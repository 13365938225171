.edit-dialog-container {
  min-width: 1400px;
  max-width: 1400px;
  min-height: 650px;
}

.edit-dialog-page-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}
.column-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.drag-handle {
  align-self: center;
  margin-top: 15px;
}

.drag-handle-invisible {
  visibility: hidden;
}

.sortable-helper {
  z-index: 9999;
  background-color: white;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
